import {getEventId} from '@wix/wix-events-commons-statics'
import {getReservationId} from '../selectors/reservation'
import {getSelectedPaidPlan} from '../selectors/paid-plans'
import {GetState} from '../types'
import {getDiscount} from './checkout'

export const SUBMIT_COUPON = 'SUBMIT_COUPON'
export const RESET_COUPON_CODE = 'RESET_COUPON_CODE'
export const COUPON_APPLIED = 'COUPON_APPLIED'

export const resetCouponCode = () => (dispatch: Function, getState: GetState) => {
  dispatch({
    type: RESET_COUPON_CODE,
  })

  const state = getState()

  if (getSelectedPaidPlan(state)) {
    dispatch(getDiscount(getEventId(state.event), getReservationId(state)))
  }
}

export const submitCoupon = (couponCode: string) => ({
  type: SUBMIT_COUPON,
  payload: couponCode,
})

export const couponApplied = () => ({
  type: COUPON_APPLIED,
})

export const applyCoupon = (eventId: string, reservationId: string, couponCode: string) => (dispatch: Function) => {
  dispatch(submitCoupon(couponCode))
  return dispatch(getDiscount(eventId, reservationId))
}
