import {
  getTaxConfig,
  getTicketServiceFee,
  getTicketTax,
  isTaxAdded,
  getTaxRateLabel,
  isDonationTicketDefinition,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {isWixFeeTypeIsAdded} from './tickets'

export const getTax = (
  event: ExtendedEvent,
  ticket: wix.events.ticketing.TicketDefinition,
  t: TFunction,
  overridePrice?: string,
) => {
  const taxConfig = getTaxConfig(event)
  const isDonation = isDonationTicketDefinition(ticket)
  const visible = Boolean(taxConfig) && !ticket.free && (!isDonation || taxConfig.appliesToDonations)
  const unspecific = isDonation && (overridePrice === undefined || overridePrice === '')

  if (!visible) {
    return null
  }

  if (!isTaxAdded(taxConfig.type)) {
    return t('tax.included', taxConfig)
  } else if (unspecific) {
    return `+${getTaxRateLabel(taxConfig)} ${taxConfig.name}`
  } else {
    return `+${getTicketTax(ticket, taxConfig, overridePrice)} ${taxConfig.name}`
  }
}

export const getFee = (
  event: ExtendedEvent,
  ticket: wix.events.ticketing.TicketDefinition,
  t: TFunction,
  overridePrice?: string,
) => {
  const taxConfig = getTaxConfig(event)
  const unspecific = isDonationTicketDefinition(ticket) && (overridePrice === undefined || overridePrice === '')

  if (!isWixFeeTypeIsAdded(ticket) || ticket.free) {
    return null
  }

  if (unspecific) {
    return `+${t('checkout_serviceFee')}`
  } else {
    return t('checkout_plusServiceFee', {fee: getTicketServiceFee(ticket, taxConfig, overridePrice)})
  }
}

export const getFees = (
  event: ExtendedEvent,
  ticket: wix.events.ticketing.TicketDefinition,
  t: TFunction,
  overridePrice?: string,
) => {
  return [getTax(event, ticket, t, overridePrice), getFee(event, ticket, t, overridePrice)].filter(Boolean).join(', ')
}

export const getFeesInfo = (event: ExtendedEvent, ticket: wix.events.ticketing.TicketDefinition, t: TFunction) => {
  return [getTax(event, ticket, t), getFee(event, ticket, t)].filter(Boolean).join(', ')
}
